import { ComponentType, useEffect } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon,
} from '@material-ui/core';
import { useWorkflow } from '../../../hooks';
import { getLatestFeedbacks } from '../../../thunks/vacancy-thunks';
import { get, map } from 'lodash';
import { cutString } from '../../../utils/data-utils';
import { IconArrowRight } from '../../icons';
import { ENTITY_VACANCY } from '../../../constants/app/entity-constants';

export const VacanciesFeedbacksLatest: ComponentType = () => {
  const dispatch = useDispatch();
  const { flow } = useWorkflow();
  const { latestFeedbacks } = useSelector(
    (state: RootStateOrAny) => state[ENTITY_VACANCY]
  );
  const hits = get(latestFeedbacks, ['hits'], []);

  useEffect(() => {
    const query = {
      take: 6,
      embed: 'resume,vacancy',
    };

    dispatch(getLatestFeedbacks(query));
  }, [dispatch, flow]);

  const items = map(hits, ({ id, position, fullName, description }, idx) => (
    <ListItem key={idx}>
      <ListItemIcon>
        <SvgIcon
          component={IconArrowRight}
          viewBox="0 0 9 16"
          color="info"
          fontSize="small"
        />
      </ListItemIcon>
      <ListItemText
        primary={fullName}
        primaryTypographyProps={{ variant: 'subtitle2' }}
        secondary={cutString(description, 128) || position}
        secondaryTypographyProps={{ variant: 'caption' }}
      />
    </ListItem>
  ));

  return <List>{items}</List>;
};
