import { ComponentType } from 'react';
import clsx from 'clsx';
import { Box, Container, Hidden } from '@material-ui/core';
import { LangSwitcher } from '../lang-switcher';
import { HeaderOwner } from './header-owner';
import { Separator } from '../separator';
import { WorkflowDropdown } from '../workflow/workflow-dropdown';
import useStyles from './styles';

interface HeaderProps {
  className?: string;
}

export const Header: ComponentType<HeaderProps> = ({ className }) => {
  const classes = useStyles();

  return (
    <header className={clsx(classes.root, className)}>
      <Container>
        <Box className={clsx(classes.container)}>
          <Hidden smDown>
            <Separator />
          </Hidden>
          <Box className={clsx(classes.action)}>
            <WorkflowDropdown />
          </Box>
          <Box className={clsx(classes.action)}>
            <LangSwitcher variant="select" />
          </Box>
          <Box className={clsx(classes.action)}>
            <HeaderOwner />
          </Box>
        </Box>
      </Container>
    </header>
  );
};
