import { ComponentType, useEffect } from 'react';
import clsx from 'clsx';
import { Box, Paper } from '@material-ui/core';
import { useAuth, useLanguage, useRouter } from '../../../hooks';
import { LangSwitcher } from '../../lang-switcher';
import { Logo } from '../../logo';
import { SignInOneId } from './sign-in-one-id';
import { dynamicRoute } from '../../../utils/route-utils';
import { ROUTES } from '../../../constants/routes/route-constants';
import useStyles from './styles';

interface SignInProps {
  className?: string;
}

export const SignIn: ComponentType<SignInProps> = ({ className }) => {
  const classes = useStyles();
  const router: any = useRouter();
  const { currentLocale } = useLanguage();
  const { isPasswordSent, isSignedIn, errors, authViaSSO, authViaCode } =
    useAuth();

  useEffect(() => {
    if (isSignedIn) router.push(`/${currentLocale}${ROUTES.DASHBOARD.ROOT}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignedIn]);

  useEffect(() => {
    if (router?.query?.code) {
      authViaCode(router?.query?.code);
      router.push(dynamicRoute(ROUTES.EMPTY));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignedIn]);

  return (
    <Box className={clsx(classes.root, className)}>
      <LangSwitcher className={classes.lang} />
      <Paper className={classes.card}>
        <Box className={classes.content}>
          <header className={classes.header}>
            <Logo className={classes.logo} />
          </header>
          <Box className={classes.body}>
            {!isPasswordSent && (
              <SignInOneId
                onClick={authViaSSO}
                errors={errors}
                size="medium"
                variant="outlined"
              />
            )}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
