import { ReactNode } from 'react';
import { PROJECT_DERC } from './project-codes-constants';
import { ROUTES } from '../routes/route-constants';
import { NewsLatest } from '../../components/news';
import { ActivitiesLatest } from '../../components/activities';
import { PortfolioLatest } from '../../components/portfolio';
import { PostsLatest } from '../../components/posts';
import { FeedbackLatest } from '../../components/feedback';
import { ServicesLatest } from '../../components/services';
import {
  VacanciesFeedbacksLatest,
  VacanciesLatest,
} from '../../components/vacancies';

export interface BlockProps {
  title: string;
  titleKey: string;
  component: ReactNode;
  url: string;
  linkText: string;
  linkKey: string;
}

export const DASHBOARD_LATEST_NEWS: BlockProps = {
  title: 'Последние новости',
  titleKey: 'news.latest',
  component: <NewsLatest />,
  url: ROUTES.NEWS.ROOT,
  linkText: 'Все новости',
  linkKey: 'news.all',
};

export const DASHBOARD_LATEST_POSTS: BlockProps = {
  title: 'Последние посты',
  titleKey: 'posts.latest',
  component: <PostsLatest />,
  url: ROUTES.POSTS.ROOT,
  linkText: 'Все посты',
  linkKey: 'posts.all',
};

export const DASHBOARD_LATEST_VACANCIES: BlockProps = {
  title: 'Последние вакансии',
  titleKey: 'vacancy.latest',
  component: <VacanciesLatest />,
  url: ROUTES.VACANCIES.ROOT,
  linkText: 'Все вакансии',
  linkKey: 'vacancy.all',
};

export const DASHBOARD_LATEST_VACANCIES_FEEDBACKS: BlockProps = {
  title: 'Отклики на вакансии',
  titleKey: 'feedbacks.vacancy',
  component: <VacanciesFeedbacksLatest />,
  url: ROUTES.VACANCIES.APPLICATIONS,
  linkText: 'Все отклики',
  linkKey: 'feedbacks.all',
};

export const DASHBOARD_LATEST_FEEDBACK: BlockProps = {
  title: 'Обратная связь',
  titleKey: 'contacts.feedback',
  component: <FeedbackLatest />,
  url: ROUTES.FEEDBACK.ROOT,
  linkText: 'Все',
  linkKey: 'all',
};

export const DASHBOARD_LATEST_ACTIVITIES: BlockProps = {
  title: 'Деятельность',
  titleKey: 'activity',
  component: <ActivitiesLatest />,
  url: ROUTES.ACTIVITIES.ROOT,
  linkText: 'Все вакансии',
  linkKey: 'vacancy.all',
};

export const DASHBOARD_LATEST_SERVICES: BlockProps = {
  title: 'Последние услуги',
  titleKey: 'services.latest',
  component: <ServicesLatest />,
  url: ROUTES.SERVICES.ROOT,
  linkText: 'Все услуги',
  linkKey: 'services.all',
};

export const DASHBOARD_LATEST_PORTFOLIO: BlockProps = {
  title: 'Последнее портфолио',
  titleKey: 'portfolio.latest',
  component: <PortfolioLatest />,
  url: ROUTES.PORTFOLIO.ROOT,
  linkText: 'Все портфолио',
  linkKey: 'portfolio.all',
};

export const DASHBOARD_LATEST_BLOCKS: Record<string, BlockProps[]> = {
  [PROJECT_DERC]: [
    DASHBOARD_LATEST_POSTS,
    DASHBOARD_LATEST_VACANCIES,
    DASHBOARD_LATEST_FEEDBACK,
    DASHBOARD_LATEST_VACANCIES_FEEDBACKS,
  ],
  DEFAULT: [
    DASHBOARD_LATEST_NEWS,
    DASHBOARD_LATEST_VACANCIES,
    DASHBOARD_LATEST_FEEDBACK,
    DASHBOARD_LATEST_VACANCIES_FEEDBACKS,
  ],
};
