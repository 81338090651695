import { createSlice } from '@reduxjs/toolkit';
import { ENTITY_ORGANIZATION } from '../constants/app/entity-constants';

const initialState = {
  catalog: {
    isLoading: false,
    hits: null,
    total: 0,
  },
  counts: {
    isLoading: false,
  },
  nodes: {
    isLoading: false,
  },
};

export const { actions: organizationActions, reducer: organizationReducer } =
  createSlice({
    name: ENTITY_ORGANIZATION,
    initialState,
    reducers: {
      //List
      setCatalogRequest: (state) => {
        state.catalog = {
          isLoading: true,
          hits: null,
          total: 0,
        };
      },
      setCatalogSuccess: (state, { payload }) => {
        state.catalog = {
          isLoading: false,
          hits: payload.hits,
          total: payload.total,
        };
      },
      setCatalogFailure: (state) => {
        state.catalog = {
          isLoading: false,
          hits: null,
          total: 0,
        };
      },

      //Counts
      setCountsRequest: (state) => {
        state.counts = {
          ...state.counts,
          isLoading: true,
        };
      },
      setCountsSuccess: (state, { payload }) => {
        state.counts = {
          ...state.counts,
          ...payload,
          isLoading: false,
        };
      },
      setCountsFailure: (state) => {
        state.counts = {
          ...state.counts,
          isLoading: false,
        };
      },

      //Nodes
      setNodesRequest: (state) => {
        state.nodes = {
          ...state.nodes,
          isLoading: true,
        };
      },
      setNodesSuccess: (state, { payload }) => {
        state.nodes = {
          ...state.nodes,
          ...payload,
          isLoading: false,
        };
      },
      setNodesFailure: (state) => {
        state.nodes = {
          ...state.nodes,
          isLoading: false,
        };
      },

      //Default
      setDefault: (state) => {
        state.catalog = {
          isLoading: false,
          hits: null,
          total: 0,
        };
        state.counts = {
          isLoading: false,
        };
        state.nodes = {
          isLoading: false,
        };
      },
    },
  });
