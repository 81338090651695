import { ApiMedia } from '../../../http';

export class FileUploader {
  private static instance: any;
  private editor: any;
  private readonly input: HTMLInputElement | any;

  constructor(editor: any) {
    if (!FileUploader.instance) {
      this.editor = editor;
      if (!this.input) {
        this.input = document.createElement('input');
        this.input.setAttribute('type', 'file');
        this.input.setAttribute('accept', '*');
      }

      FileUploader.instance = this;
    }
    return FileUploader.instance;
  }

  upload() {
    this.input.click();

    this.input.onchange = () => {
      this.send(this.input.files[0]).then((res: any) => {
        this.input.value = '';
        this.editor.model.change((writer: any) => {
          const insertPosition =
            this.editor.model.document.selection.getFirstPosition();
          writer.insertText(res.fullPath, {}, insertPosition);
        });
      });
    };
  }

  async send(file: File) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', file);
      ApiMedia.createOne(formData).then(resolve).catch(reject);
    });
  }
}
