import { ComponentType, ReactNode, useEffect } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import { useWorkflow } from '../../../hooks';
import { getLatest } from '../../../thunks/feedback-thunks';
import { map, get, forEach } from 'lodash';
import { IconArrowRight } from '../../icons';
import { ENTITY_FEEDBACK } from '../../../constants/app/entity-constants';
import { cutString } from '../../../utils/data-utils';

const renderBody = (body: Record<string, any>) => {
  const records: ReactNode[] = [];

  forEach(body, (value, key) => {
    records.push(
      <Grid container key={key} spacing={1} flexWrap="nowrap">
        <Grid item>
          <Typography variant="body3">{key}:</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body3">{cutString(value, 64)}</Typography>
        </Grid>
      </Grid>
    );
  });

  return records;
};

export const FeedbackLatest: ComponentType = () => {
  const dispatch = useDispatch();
  const { flow } = useWorkflow();
  const { latest } = useSelector(
    (state: RootStateOrAny) => state[ENTITY_FEEDBACK]
  );
  const hits = get(latest, ['hits'], []);

  useEffect(() => {
    const query = {
      take: 4,
    };

    dispatch(getLatest(query));
  }, [dispatch, flow]);

  const items = map(hits, ({ id, title, body }, idx) => (
    <ListItem key={idx}>
      <ListItemIcon>
        <SvgIcon
          component={IconArrowRight}
          viewBox="0 0 9 16"
          color="info"
          fontSize="small"
        />
      </ListItemIcon>
      <ListItemText
        primary={renderBody(body)}
        primaryTypographyProps={{ variant: 'caption' }}
        secondaryTypographyProps={{ variant: 'caption' }}
      />
    </ListItem>
  ));

  return <List>{items}</List>;
};
