export const FIELD_ID = 'id';
export const FIELD_EMAIL = 'email';
export const FIELD_PHONE = 'phone';
export const FIELD_PASSWORD = 'password';
export const FIELD_SEARCH = 'search';
export const FIELD_ACTION = 'action';
export const FIELD_NAME = 'name';
export const FIELD_TITLE = 'title';
export const FIELD_SHORT_TITLE = 'shortTitle';
export const FIELD_SLUG = 'slug';
export const FIELD_URL = 'url';
export const FIELD_SHORT_DESCRIPTION = 'shortDescription';
export const FIELD_DESCRIPTION = 'description';
export const FIELD_LANGUAGE = 'language';
export const FIELD_COVER = 'cover';
export const FIELD_LOGO = 'logo';
export const FIELD_CATEGORIES = 'categories';
export const FIELD_TAGS = 'tags';
export const FIELD_TAG = 'tag';
export const FIELD_CATEGORY = 'category';
export const FIELD_PROJECTS = 'projects';
export const FIELD_ACTIVITIES = 'activities';
export const FIELD_CREATED_BY = 'createdBy';
export const FIELD_UPDATED_AT = 'updatedAt';
export const FIELD_CREATED_AT = 'createdAt';
export const FIELD_IS_ACTIVE = 'isActive';
export const FIELD_COLORS = 'colors';
export const FIELD_COLOR_START = 'colorStart';
export const FIELD_COLOR_END = 'colorEnd';
export const FIELD_STEP_TITLE = 'stepTitle';
export const FIELD_STEPS = 'steps';
export const FIELD_YOUTUBE = 'youtubeLink';
export const FIELD_PHOTO_GALLERY = 'photoGallery';
export const FIELD_PHOTO = 'PHOTO';
export const FIELD_VIDEO = 'VIDEO';
export const FIELD_FULL_NAME = 'fullName';
export const FIELD_POSITION = 'position';
export const FIELD_DAY_OF_ADMISSION = 'daysOfAdmission';
export const FIELD_FACEBOOK = 'facebookLink';
export const FIELD_INSTAGRAM = 'instagramLink';
export const FIELD_TELEGRAM = 'telegramLink';
export const FIELD_LEX_ITEMS = 'lexItems';
export const FIELD_DETAIL_COVER = 'detailCover';
export const FIELD_STAFFS = 'staffs';
export const FIELD_DEPARTMENT = 'department';
export const FIELD_FIRST_NAME = 'firstName';
export const FIELD_LAST_NAME = 'lastName';
export const FIELD_PIN = 'pin';
export const FIELD_AVATAR = 'avatar';
export const FIELD_ORGANIZATION = 'organization';
export const FIELD_WEIGHT = 'weight';
export const FIELD_COMPLETED = 'completed';
export const FIELD_COMPLETED_AT = 'completedAt';
export const FIELD_LAST_VISITED_AT = 'lastVisitedAt';
export const FIELD_FEEDBACK = 'feedback';
export const FIELD_VACANCY = 'vacancy';
export const FIELD_RESUME = 'resume';
export const FIELD_KEY = 'key';
export const FIELD_PARENT = 'parent';
export const FIELD_CODE = 'code';
export const FIELD_BODY = 'body';
export const FIELD_ATTACHMENT = 'attachment';
export const FIELD_CAPTION = 'caption';
export const FIELD_QAS = 'qas';
