import { ChangeEvent, ComponentType, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { map, isEmpty } from 'lodash';
import { IconTrash } from '../../icons';
import {
  Box,
  Button,
  Collapse,
  Fade,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  SvgIcon,
} from '@material-ui/core';
import { AppEditor, AppInput } from '../../inputs';
import { useDialog } from '../../../hooks';
import { ConfirmDialog } from '../../dialogs';

type FieldTypes = 'question' | 'answer';

interface RowProps {
  question: string;
  answer: string;
}

interface FaqRepeaterProps {
  className?: string;
  label?: string;
  value?: RowProps[];
  error?: boolean;
  helperText?: string;
  errorAppearance?: 'collapse' | 'fade';
  onChange?: (value: RowProps[]) => void;
}

export const FaqRepeater: ComponentType<FaqRepeaterProps> = ({
  className,
  label,
  value = [],
  error = false,
  helperText,
  errorAppearance = 'collapse',
  onChange,
}) => {
  const { t } = useTranslation();
  const [openConfirmation] = useDialog(ConfirmDialog);
  const [rows, setRows] = useState<RowProps[]>([
    {
      question: '',
      answer: '',
    },
  ]);

  const isAddDisabled =
    rows[rows.length - 1].question === '' ||
    rows[rows.length - 1].answer === '';

  const handleAdd = () => {
    setRows((prev: RowProps[]) => {
      const val = [...prev];
      return [
        ...val,
        {
          question: '',
          answer: '',
        },
      ];
    });
  };

  const handleRemove = (idx: number) => {
    openConfirmation({
      resolve: () => {
        setRows((prev: RowProps[]) => {
          const val = [...prev];
          val.splice(idx, 1);
          return val;
        });
      },
    });

    handleBlur();
  };

  const handleChange =
    (idx: number, field: FieldTypes) => (e: ChangeEvent<HTMLInputElement>) => {
      setRows((prev: RowProps[]) => {
        const val = [...prev];
        val[idx][field] = e.target.value;
        return val;
      });
    };

  const handleChangeEditor =
    (idx: number, field: FieldTypes) => (e: any, editor: any) => {
      setRows((prev: RowProps[]) => {
        const val = [...prev];
        val[idx][field] = editor.getData();
        return val;
      });
    };

  const handleBlur = () => {
    if (onChange) onChange(rows);
  };

  const inputLabel = () => {
    if (!label) return null;

    return (
      <InputLabel shrink style={{ marginBottom: 4 }}>
        {label}
      </InputLabel>
    );
  };

  const helper = () => {
    const text = (
      <FormHelperText
        error={error}
        component="div"
        style={{ textAlign: 'right' }}
      >
        {helperText}
      </FormHelperText>
    );

    if (errorAppearance === 'fade') {
      return <Fade in={!!helperText}>{text}</Fade>;
    }

    return <Collapse in={!!helperText}>{text}</Collapse>;
  };

  useEffect(() => {
    if (!isEmpty(value)) setRows(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={clsx(className)}>
      {inputLabel()}
      {!isEmpty(rows) && (
        <Box mb={1}>
          {map(rows, ({ question, answer }, idx) => (
            <Box
              component={Grid}
              container
              spacing={1}
              borderBottom="1px solid"
              borderColor="grey.500"
              py={2}
              key={idx}
            >
              <Grid item xs={4}>
                <InputLabel shrink>{t('faq.question', 'Вопрос')}</InputLabel>
                <AppInput
                  onBlur={handleBlur}
                  value={question}
                  onChange={handleChange(idx, 'question')}
                />
              </Grid>
              <Grid item xs={7}>
                <InputLabel shrink>{t('faq.answer', 'faq.answer')}</InputLabel>
                <Box>
                  <AppEditor
                    onBlur={handleBlur}
                    value={answer}
                    onChange={handleChangeEditor(idx, 'answer')}
                  />
                </Box>
              </Grid>
              <Grid item>
                <br />
                <IconButton onClick={() => handleRemove(idx)} color="error">
                  <SvgIcon component={IconTrash} viewBox="0 0 16 16" />
                </IconButton>
              </Grid>
            </Box>
          ))}
        </Box>
      )}
      {helper()}
      <Button
        onClick={handleAdd}
        color="info"
        disabled={isAddDisabled}
        fullWidth
      >
        {t('add.more', 'Добавить еще')}
      </Button>
    </Box>
  );
};
