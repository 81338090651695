import { ComponentType } from 'react';
import clsx from 'clsx';
import { Box, CircularProgress } from '@material-ui/core';
import useStyles from './styles';

interface LoaderProps {
  size?: number;
  className?: string;
}

export const Loader: ComponentType<LoaderProps> = ({
  size = 64,
  className,
}) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root, className)}>
      <CircularProgress color="info" size={size} />
    </Box>
  );
};
