import { ComponentType } from 'react';
import clsx from 'clsx';
import { Box, Container } from '@material-ui/core';
import useStyles from './styles';

interface LayoutSimpleProps {
  className?: any;
  children?: any;
}

export const LayoutSimple: ComponentType<LayoutSimpleProps> = ({
  className,
  children,
}) => {
  const classes = useStyles();
  return (
    <Box className={clsx(classes.root, className)}>
      <Container className={classes.container}>{children}</Container>
    </Box>
  );
};
